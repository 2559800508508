import React from "react";
import window from "global/window";
import "./style.css";
import useWindowOffset from "../../hooks/useWindowOffset";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import { Up } from "grommet-icons";

export default () : JSX.Element => {

    const offset = useWindowOffset();
    const dimensions = useWindowDimensions();
    const isVisible: boolean = offset >= dimensions.height;
    const onClick: Function = () => window.scrollTo({ top: 0, behavior: "smooth" });

    return (
        <div className={`go-top btn btn-large btn-rounded visible-${isVisible}`} onClick={onClick}>
            <Up />
        </div>
    )

}