import React, { useState } from "react";
import "./style.css";

import { Tooltip } from "reactstrap";

export default ({ name, icon }: {
    icon: JSX.Element,
    name: String
}): JSX.Element => {

    const [tooltipOpen, setTooltipOpen] = useState<Boolean>(false);
    const toggle: Function = () => setTooltipOpen(!tooltipOpen);
    const iconId: String = `stack-icon-${name.toLocaleLowerCase()}`;

    return (
        <React.Fragment>
            <div className="stack-icon" id={iconId}>
                {icon}
            </div>
            <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target={iconId}
                toggle={toggle}
            >
                {name}
            </Tooltip>
        </React.Fragment>
    );

}