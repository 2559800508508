import React from "react";
import "./style.css";

export default ({ icon, name, value }: {
    icon: JSX.Element,
    name: String,
    value: String
}): JSX.Element => {
    return (
        <div className="contact-icon-wrapper">
            <div className="contact-icon">
                {icon}
            </div>
            <h6>{name}</h6>
            <p>{value}</p>
        </div>
    );
}