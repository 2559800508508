import React, { ReactNode } from "react"
import "./style.css";

interface IconCardInterface {
    title: String
    text: String
    icon: ReactNode
}

export default (props: IconCardInterface) : JSX.Element => {

    const { title, text, icon } = props;

    return (
        <div className="icon-card-wrapper">
            <div className="card-content">
                <div className="icon-wrapper">
                    {icon}
                </div>
                <div className="icon-text">
                    <h6>{title}</h6>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    );

}