import { useEffect, useState } from "react";

export default (): Number => {

    const initialState: Number = 0;
    const [offset, setOffset] = useState(initialState);

    useEffect(() : Function => {

        const handleScroll = () => {

            const getElementOffsets: Function = (): Number => {
                return window.scrollY;
            }

            setOffset(getElementOffsets());

        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);

    }, [offset]);

    return offset;

}