import React, { ReactChild, ReactChildren } from "react"
import document from "global/document";
import window from "global/window";
import ClassComponent from "../../interfaces/classable";

interface ScrollToInterface extends ClassComponent {
    sectionID: String,
    children: ReactChild | ReactChildren;
}

export default ({ sectionID, children, className }: ScrollToInterface): JSX.Element => {

    const onClick: Function = () => {

        const element: HTMLElement | null = document.getElementById(sectionID);

        if(element) {

            const elementPos: Number = element.offsetTop;
            const position: Number = elementPos - 120;

            window.scrollTo({
                top: position,
                behavior: "smooth"
            });

        }
    }

    return (
        <div
            className={`scroll-to ${className}`}
            onClick={onClick}
        >
            {children}
        </div>
    );

}