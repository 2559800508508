import React from "react";
import useProjects from "../../queries/projects";
import "./style.css";

import { Container } from "reactstrap";
import Section from "../../components/section";
import ProjectCard from "../../components/project-card";

export default () : JSX.Element => {

    const { edges } = useProjects();

    const projectList = edges.map(({ node }, index) => {
        return (
            <ProjectCard
                key={node.frontmatter.title}
                title={node.frontmatter.title}
                description={node.html}
                tags={node.frontmatter.tags}
                image={node.frontmatter.image && node.frontmatter.image.childImageSharp.fluid}
                url={node.frontmatter.url}
                buy_url={node.frontmatter.buy_url}
                invertDirection={!(index % 2 === 0)}
            />
        )
    })

    return (
        <Section id="projects">
            <Container>
                <header>
                    <h6 className="accent-header">See all my work</h6>
                    <h1><span>My</span> Projects</h1>
                </header>
                {projectList}
            </Container>
        </Section>
    )

}