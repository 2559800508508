import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";

interface ProjectsQueryResponse {
    edges: [{
        node: {
            id: String
            html: String
            frontmatter: {
                title: String
                id: String
                tags: String
                url: String
                buy_url: String
                image: {
                    childImageSharp: {
                        fluid: FluidObject
                    }
                }
            }
        }
    }]
}

interface ProjectsQuery {
    allMarkdownRemark: ProjectsQueryResponse
}

const useProjects = ():ProjectsQueryResponse => {

    const data: ProjectsQuery = useStaticQuery(graphql`
        {
            allMarkdownRemark(filter: {frontmatter: {type: {eq: "project"}}}) {
                edges {
                    node {
                        id
                        html
                        frontmatter {
                            id
                            title
                            tags
                            url
                            buy_url
                            image {
                                childImageSharp {
                                    fluid(quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return data.allMarkdownRemark;

};

export default useProjects;

