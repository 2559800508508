import React from "react";
import "./style.css";

import { StackIcon as IconList } from "../../constants/icons";
import { Container, Row } from "reactstrap";
import StackIcon from "../../components/stack-icon";
import Section from "../../components/section";

export default (): JSX.Element => {

    const icons = Object.entries(IconList).map(([key, icon]) => (
        <StackIcon key={key} icon={icon} name={key} />
    ));

    return (
        <Section id="stack-list">
            <Container>
                <header>
                    <h6 className="accent-header">The most advanced tools</h6>
                    <h1><span>My</span> Stack</h1>
                </header>
                <Row className="stack-row">
                    {icons}
                </Row>
                <header className="mb-0">
                    <p><em>And many more...</em></p>
                </header>
            </Container>
        </Section>
    )
};