import React from "react";
import "./style.css";

import Lottie from "react-lottie";
import Section from "../../components/section";
import ScrollTo from "../../components/scroll-to";
import * as animationData from "../../animations/intro-anim.json";
import { Container, Row, Col, Button } from "reactstrap";

export default () : JSX.Element => {

    const animOpts = {
        loop: true,
        autoplay: true,
        animationData: animationData.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <Section
            id="main-header"
            expanded={true}
            accountNavbar={true}
        >
            <Container>
                <Row className="double-gutter">
                    <Col xs={12} sm={12} md={7} lg={6}>
                        <h1> A Full Web Stack Developer <span>making state of the art apps.</span></h1>
                        <p>I craft complex web applications and elegant masterpieces through a complete, scalable and extensive software architecture for development. All within the latest and most modern stack.</p>
                        <div className="d-flex align-items-center main-header-buttons">
                            <ScrollTo sectionID="projects">
                                <Button size="lg">Projects</Button>
                            </ScrollTo>
                            <a href="/resume.pdf">
                                <Button size="lg" outline className="ml-4">See CV</Button>
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={5} lg={6} className="lottie-anim">
                        <Lottie
                            options={animOpts}
                            isStopped={false}
                            isPaused={false}
                        />
                    </Col>
                </Row>
            </Container>
        </Section>
    )
};