import React from "react";
import useDevelopers from "../../queries/developers";
import "./style.css";

import { Col, Row, Container } from "reactstrap";
import Section from "../../components/section";
import DeveloperCard from "../../components/developer-card";

export default (): JSX.Element => {

    const developers = useDevelopers();
    const developerList = developers.edges.map(dev => {

        const info = dev.node.frontmatter;

        return (
            <Col xs={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 6, offset: 3 }} lg={{ size: 6, offset: 3 }} key={info.id}>
                <DeveloperCard
                    key={info.id}
                    bio={dev.node.html}
                    title={info.title}
                    firstname={info.firstname}
                    lastname={info.lastname}
                    image={info.image.childImageSharp.fluid}
                />
            </Col>
        );

    })

    return (
        <Section id="developer-list">
            <Container>
                <header>
                    <h6 className="accent-header">A little bit of my journey</h6>
                    <h1>About <span>me</span></h1>
                </header>
                <Row className="double-gutter">
                    {developerList}
                </Row>
            </Container>
        </Section>
    )
}