import React from "react";
import "./style.css";
import useSiteMetadata from "../../queries/sitemeta";

import Section from "../../components/section";
import ContactIcon from "../../components/contact-icon";
import { ContactIcon as ContactIcons } from "../../constants/icons";
import { Row, Container, Col } from "reactstrap";

export default (): JSX.Element => {

    const { contact } = useSiteMetadata();

    const contactItems = Object.entries(contact).map(([key, value], index) => (
        <Col key={key} xs={12} sm={6} lg={4} md={4} className={`${index % 2 !== 0 ? "dotted" : ""}`}>
            <ContactIcon
                icon={ContactIcons[key]}
                name={key}
                value={value}
            />
        </Col>
    ));

    return (
        <Section id="contact">
            <Container>
                <header>
                    <h6 className="accent-header">Get in touch with me!</h6>
                    <h1><span>I'd like to</span> speak with you</h1>
                    <p>Let's start with the requirements of your new app today! I'll guide you on any technical aspect your app should have.</p>
                </header>
                <Row className="stack-row">
                    {contactItems}
                </Row>
            </Container>
        </Section>
    );

}