import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../../queries/sitemeta";

interface SiteSEO {
    title: String
    description: String
    lang: String
    author: String
    banner: String
}

export default () : JSX.Element => {

    const siteMetadata = useSiteMetadata();

    const SEO:SiteSEO = {
        title: siteMetadata.title,
        description: siteMetadata.description,
        author: siteMetadata.author,
        lang: "en",
        banner: "https://xaes.dev./banner.png"
    };

    return (
        <Helmet
            htmlAttributes={{ lang: SEO.lang }}
            title={SEO.title}
            meta={[
                {
                    name: `description`,
                    content: SEO.description,
                },
                {
                    property: `og:title`,
                    content: `${siteMetadata.title}`,
                },
                {
                    property: `og:description`,
                    content: SEO.description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: SEO.banner
                },
                {
                    property: `twitter:image`,
                    content: SEO.banner
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: SEO.author,
                },
                {
                    property: `og:locale`,
                    content: SEO.lang
                },
                {
                    property: `og:site_name`,
                    content: siteMetadata.title
                },
                {
                    name: `twitter:title`,
                    content: `${siteMetadata.title} | ${SEO.title}`,
                },
                {
                    name: `twitter:description`,
                    content: SEO.description,
                },
            ]}
        />
    );

}