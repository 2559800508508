import { FluidObject } from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

export default (): FluidObject => {

    const data = useStaticQuery(graphql`
        {
            file(relativePath: {eq: "hire-us-banner.jpg"}) {
                childImageSharp {
                    fluid(fit: COVER, maxWidth: 1200, webpQuality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return data.file.childImageSharp.fluid;

}