import React from "react";
import "./style.css";

import { Launch, Code, Schedules, Configure } from "grommet-icons";
import { Container, Row, Col } from "reactstrap";
import IconCard from "../../components/icon-card";
import Section from "../../components/section";

export default () : JSX.Element => {
    return (
        <Section
            id="workflow"
        >
            <Container>
                <Row>
                    <Col xs={12} sm={6} lg={3} md={6}>
                        <IconCard text="Development cycle's most important step. Any system will just be as good as its architectural design." title="Design" icon={<Schedules />} />
                        <IconCard text="Docker, a cluster for your app, Kubernetes? Use of AWS to ensure an automated / cost efficient launch." title="Launch" icon={<Launch />} />
                    </Col>
                    <Col xs={12} sm={6} lg={3} md={6}>
                        <IconCard text="An elegant, efficient and even artistic code implementation of our previous scalable design." title="Development" icon={<Code />} />
                        <IconCard text="Our apps are based on future-proof stack, no one has to worry for big updates and migrations." title="Maintenance" icon={<Configure />} />
                    </Col>
                    <Col xs={12} sm={12} lg={6} md={12} className="double-gutter order-first order-lg-last">
                        <h6 className="accent-header">Cutting-edge development experiences</h6>
                        <h1>I cover the <span>whole development cycle</span> of your web app</h1>
                        <p>
                            I value my craft, have a deep passion for progression, and exist to solve challenges
                            for projects I believe in. Since the beginning, my mission has been to do great work
                            and to have a great time doing it. <br /><br /> I believe our mission has guided me well.
                            I build valuable apps and digital experiences that people love.
                        </p>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}