import React, { FunctionComponent } from "react";
import SEO from "./seo";
import Navbar from "./navbar";
import GoTop from "./go-top";

const Layout:FunctionComponent = ({children}: {children: React.ReactNode | React.ReactNode[]}) => {
    return(
        <React.Fragment>
            <SEO />
            <Navbar />
            <main>
                {children}
            </main>
            <GoTop />
        </React.Fragment>
    )
}

export default Layout;