import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ClassComponent from "../../interfaces/classable";
import "./style.css";

interface SectionProps extends ClassComponent {
    children: React.ReactNode | React.ReactNode[]
    accountNavbar?: boolean
    expanded?: Boolean
}

const Section = (props: SectionProps): JSX.Element => {

    const { height } = useWindowDimensions();

    const {
        accountNavbar = false,
        expanded = false,
        children,
        id = "",
        className = ""
    } = props;

    const expandedStyle = (expanded) ? {
        height: height || "100vh",
        width: "100%"
    } : null;

    return (
        <section
            id={id}
            className={`custom-section background ${expanded && "expanded"} ${accountNavbar && "account-navbar"} ${className}`}
            style={expandedStyle}
        >
            {children}
        </section>
    );

};

export default Section;