import React, { useState } from "react";
import useSiteMetadata from "../../queries/sitemeta";
import useWindowOffset from "../../hooks/useWindowOffset";

import ScrollTo from "../scroll-to";
import Image from "gatsby-image";
import { Link } from "gatsby";
import { Collapse, Navbar, Nav, NavItem, Container, Button } from "reactstrap";
import { Menu, Close } from "grommet-icons";

import "./style.css";

const MainNavbar = ({ scrollModeOffset = 1 } : { scrollModeOffset: Number }) : JSX.Element => {

    const windowOffset = useWindowOffset();
    const isScrolled: Boolean = windowOffset >= scrollModeOffset;

    // Collapse Controller.

    const [isOpen, setIsOpen] = useState<Boolean>(false);
    const toggle:Function = () => setIsOpen(!isOpen);

    // Metadata Query.

    const siteMetadata = useSiteMetadata();

    // Mapping Links.

    const links = siteMetadata.links.map(({ sectionID, label }: { sectionID: string, label: string })  => {
        return (
            <NavItem key={label}>
                <ScrollTo sectionID={sectionID} className="nav-link">
                    <Button color="link" className="nav-link">{label}</Button>
                </ScrollTo>
            </NavItem>
        );
    });

    return (
        <Navbar id="main-navbar" className={`scrolled-${isScrolled} collapsed-${isOpen}`} expand="lg">
            <Container>
                <Link className="navbar-brand" to="/">
                    <Image fluid={siteMetadata.logo} />
                </Link>
                <Button color="link" onClick={toggle} className="nav-toggler">
                    {isOpen ? <Close /> : <Menu />}
                </Button>
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        {links}
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );

}

export default MainNavbar;