import React from "react";
import useSiteMetadata from "../../queries/sitemeta";
import useQuery from "./query";
import "./style.css";

import Section from "../section";
import BackgroundImage from "gatsby-background-image";
import { Button, Container } from "reactstrap";

export default (): JSX.Element => {

    const metadata = useSiteMetadata();
    const banner = useQuery();

    return (
        <Section>
            <Container>
                <BackgroundImage
                    fluid={banner}
                    Tag="div"
                    className="hire-us gradient gradient1"
                >
                    <header>
                        <h6 className="accent-header">Let's get started with your project now</h6>
                        <h1><span>Let us know</span> how can I help you <span>by sending a Fiverr Offer!</span></h1>
                        <a href={metadata.fiverr} className="project-btn" target="_blank" rel="noopener">
                            <Button color="primary" size="lg">Check out my Fiverr!</Button>
                        </a>
                    </header>
                </BackgroundImage>
            </Container>
        </Section>
    );

}