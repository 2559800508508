import React from "react";
import "./style.css";
import useSiteMetadata from "../../queries/sitemeta";

import { Container } from "reactstrap";

export default (): JSX.Element => {

    const { title } = useSiteMetadata();

    return (
        <footer>
            <Container>
                <p>© {title}, {new Date().getFullYear()}. - All rights reserved.</p>
            </Container>
        </footer>
    );

}