import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";

interface DevelopersQueryResponse {
    edges: [{
        node: {
            id: String
            html: String
            frontmatter: {
                title: String
                id: String
                firstname: String
                lastname: String
                image: {
                    childImageSharp: {
                        fluid: FluidObject
                    }
                }
            }
        }
    }]
}

interface DevelopersQuery {
    allMarkdownRemark: DevelopersQueryResponse
}

const useDevelopers = ():DevelopersQueryResponse => {

    const data: DevelopersQuery = useStaticQuery(graphql`
        {
            allMarkdownRemark(filter: {frontmatter: {type: {eq: "developer"}}}) {
                edges {
                    node {
                        id
                        html
                        frontmatter {
                            id
                            title
                            firstname
                            lastname
                            image {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return data.allMarkdownRemark;

};

export default useDevelopers;

