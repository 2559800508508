import React from "react";

// Stack Icons.

import Apollo from "../assets/svg/apollo.svg";
import Docker from "../assets/svg/docker.svg";
import Gatsby from "../assets/svg/gatsby.svg";
import Java from "../assets/svg/java.svg";
import MongoDB from "../assets/svg/mongodb.svg";
import Neo4J from "../assets/svg/neo4j.svg";
import NextJS from "../assets/svg/nextjs.svg";
import NodeJS from "../assets/svg/nodejs.svg";
import Postgres from "../assets/svg/postgres.svg";
import Python from "../assets/svg/python.svg";
import Quarkus from "../assets/svg/quarkus.svg";
import Falcon from "../assets/svg/falcon.svg";
import MySQL from "../assets/svg/mysql.svg";
import Typescript from "../assets/svg/typescript.svg";
import AWS from "../assets/svg/aws.svg";

// Contact Icons.

import { Phone, MailOption, MapLocation } from "grommet-icons";

interface IconMap {
    readonly [key: String]: JSX.Element
}

export const StackIcon: IconMap = {
    Apollo: <Apollo />,
    Docker: <Docker />,
    Java: <Java />,
    MongoDB: <MongoDB />,
    Neo4J: <Neo4J />,
    Gatsby: <Gatsby />,
    NextJS: <NextJS />,
    AWS: <AWS />,
    Typescript: <Typescript />,
    MySQL: <MySQL />,
    Falcon: <Falcon />,
    NodeJS: <NodeJS />,
    PostgreSQL: <Postgres />,
    Python: <Python />,
    Quarkus: <Quarkus />,
};

export const ContactIcon: IconMap = {
    phone: <Phone />,
    email: <MailOption />,
    location: <MapLocation />,
};
