import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image"

interface SiteMetadataQuery {
    site: {
        siteMetadata: {
            title: String,
            description: String,
            author: String,
            fiverr: String
            links: [{
                sectionID: String,
                label: String
            }],
            contact: {
                email: String,
                phone: String
                location: String
            }
        },
    };
    file: {
        childImageSharp: {
            fluid: FluidObject
        }
    }
}

interface SiteMetadataResponse {
    title: String
    description: String
    author: String
    fiverr: String
    links: [{
        sectionID: String
        label: String
    }]
    contact: {
        email: String
        phone: String
        location: String
    }
    logo: FluidObject
}

const useSiteMetadata = ():SiteMetadataResponse => {

    const data:SiteMetadataQuery = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    author
                    description
                    fiverr
                    title
                    links {
                        sectionID
                        label
                    }
                    contact {
                        email
                        phone
                        location
                    }
                }
            }
            file(relativePath: {eq: "logo.png"}) {
                childImageSharp {
                    fluid(fit: COVER, maxWidth: 150, maxHeight: 150, webpQuality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return {
        ...data.site.siteMetadata,
        logo: data.file.childImageSharp.fluid
    };

}

export default useSiteMetadata;