import React from "react";
import Image, { FluidObject } from "gatsby-image";
import "./style.css";

interface DeveloperCardProps {
    title: String
    image: FluidObject
    firstname: String
    lastname: String
    bio: String
}

export default (props: DeveloperCardProps): JSX.Element => {
    return (
        <div className="developer-card">
            <div className="developer-image">
                <Image fluid={props.image} />
            </div>
            <div className="developer-info">
                <h3><span>{props.firstname}</span> {props.lastname}</h3>
                <h6 className="accent-header">{props.title}</h6>
                <div className="developer-bio" dangerouslySetInnerHTML={{ __html: props.bio }}/>
            </div>
        </div>
    );
}