import { useEffect, useState } from "react";
import { ViewportSizes } from "../constants/viewports";
import WindowDimensionInterface from "../interfaces/windowDimensions";
import window from "global/window";

export default (onResize?: Function) : WindowDimensionInterface => {

    const getWindowDimensions: Function = () : { width: Number, height: Number } => {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    };

    const classifyViewport: Function = (width: Number) : String => {
        if(width < ViewportSizes.SM) return "XS";
        if(width >= ViewportSizes.SM && width < ViewportSizes.MD) return "SM";
        if(width >= ViewportSizes.MD && width < ViewportSizes.LG) return "MD";
        if(width >= ViewportSizes.LG && width < ViewportSizes.XLG) return "LG";
        if(width >= ViewportSizes.XLG) return "XLG";
    };

    // Building Initial State.

    const initialDimensions = getWindowDimensions();
    const initialState: WindowDimensionInterface = {
        ...initialDimensions,
        viewport: classifyViewport(initialDimensions.width)
    }

    const [windowDimensions, setWindowDimensions] = useState(initialState);

    useEffect((): Function => {

        const handleResize: Function = () => {

            // Setting new Dimensions in State.

            const dim = getWindowDimensions();
            setWindowDimensions({...dim, viewport: classifyViewport(dim.width)});

            // Execute "onResize" after resize.

            clearTimeout(window.resizedFinished);
            if(onResize) window.resizedFinished = setTimeout(onResize, 250);

        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    });

    return windowDimensions;

};
