import React from "react";
import Image, { FluidObject } from "gatsby-image";
import "./style.css";

import { Col, Row, Button } from "reactstrap";

interface ProjectCardProps {
    tags: String
    title: String
    description: String
    image: FluidObject | null
    invertDirection?: Boolean
    buy_url?: String
    url?: String
}

export default (props: ProjectCardProps) : JSX.Element => {
    return (
        <div className="project-card">
            <Row className="double-gutter">
                <Col xs={12} sm={12} md={6} lg={5}>
                    <div className="project-card-info">
                        <h6 className="accent-header">{props.tags}</h6>
                        <h1>{props.title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: props.description }} />
                        {props.url &&
                            <a href={props.url} className="project-btn" target="_blank" rel="noopener">
                                <Button size="lg">See Project</Button>
                            </a>
                        }
                        {props.buy_url &&
                            <a href={props.buy_url} className="project-btn" target="_blank" rel="noopener">
                                <Button outline size="lg">Buy Now</Button>
                            </a>
                        }
                    </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={7} className={props.invertDirection ? "order-last order-md-first" : ""}>
                    {props.image ? (
                        <div className="project-card-image">
                            <Image fluid={props.image} />
                        </div>
                    ) : undefined}
                </Col>
            </Row>
        </div>
    )
}